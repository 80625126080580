<template>
  <div class="main-report-container">
    <h1>Reports</h1>
    <div>
      <div>
        <div class="grid">
          <div class="col-6">
            <p class="label">Choose a Report</p>
            <form-select :value="selected" :options="categoryList" option-label="name" label="" :default-choice="`Select`" class="reports-select" @change="onChange" />

            <div v-if="isLocations">
              <p class="label">Choose a Location</p>
              <form-select :value="locationSelected" :options="oneLocations" option-label="name" label="" :default-choice="`Select`" class="reports-select" @change="locationChange" />
            </div>
            <div v-if="showDriverReports">
              <div v-if="showFilters" class="filters align-bottom module invoice-date-filters">
                <div class="filter-options">
                  <div class="filter-input report-filter-input">
                    <div class="field">
                      <p class="label">Begin Date</p>
                      <date-pick :value="period.startDate" :display-format="'MM/DD/YYYY'" :format="'MM/DD/YYYY'" @input="updateStartDate($event)">
                        <template #default="{ toggle }">
                          <div class="input-has-icon input-icon-tab">
                            <div class="input-icon" @click="toggle"><i class="icon-calendar"></i></div>
                            <input id="startDate" :value="period.startDate" type="text" placeholder="Begin Date" readonly required @click="toggle" />
                          </div>
                        </template>
                      </date-pick>
                    </div>
                  </div>
                  <div class="filter-input report-filter-input">
                    <div class="field">
                      <p class="label">End Date</p>
                      <date-pick :value="period.endDate" :display-format="'MM/DD/YYYY'" :format="'MM/DD/YYYY'" @input="updateEndDate($event)">
                        <template #default="{ toggle }">
                          <div class="input-has-icon input-icon-tab">
                            <div class="input-icon" @click="toggle"><i class="icon-calendar"></i></div>
                            <input id="endDate" :value="period.endDate" type="text" placeholder="End Date" readonly required @click="toggle" />
                          </div>
                        </template>
                      </date-pick>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="isError" class="bottom-20">
                <p class="error-message"><i class="icon icon-alert-triangle" style="color: #c47d2b; font-size: 18px"></i> {{ message }}</p>
              </div>
              <button class="button" v-if="showBtn" :disabled="disableBtn" @click="downloadCsv">
                <span v-if="showDownload"><i class="icon icon-download"></i> Download CSV</span>
                <span v-if="csvLoader"><i class="icon icon-loader"></i> Loading...</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
.main-report-container .bottom-20 {
  padding-bottom: 20px;
}
.main-report-container .spread-md {
  max-width: 80%;
}
.main-report-container .reports-select {
  width: 80%;
}
.main-report-container .report-filter-input {
  margin-right: 32px;
}
.main-report-container .tags .tag {
  max-width: 80%;
}
</style>
<script>
import FormSelect from "@/components/forms/fields/FormSelect";
import DatePick from "vue-date-pick";
import { restApi } from "../../../http/http.config";
import { encodeWithParam } from "../../../util/Base64Encoding";
import { downloadFile } from "../../../filters/index";
import DateTime from "luxon/src/datetime";

export default {
  name: "ReportsPage",
  components: {
    FormSelect,
    DatePick,
  },
  data: function () {
    return {
      selected: "",
      fileName: "Partner Reports",
      selectedClient: [],
      reportsCsv: [],
      resetPicker: false,
      oneLocations: [],
      isLocations: false,
      locationSelected: "",
      period: {
        startDate: "",
        endDate: "",
      },
      client: {
        id: "",
        name: "",
      },
      message: "",
      isError: false,
      showFilters: false,
      csvLoader: false,
      showDownload: true,
      showResponseText: false,
      ResponseText: "",
      disableBtn: false,
      showDriverReports: false,
      showBtn: false,
      category: [
        { id: "drivers-approved", name: "Drivers Approved", label: "Drivers Approved" },
        // { id: "candidates-approved", name: "Candidates Approved", label: "Candidates Approved" },
        { id: "eligibility-questions", name: "Eligibility Questions", label: "Eligibility Questions" },
        // { id: "location-on/off", name: "Location On/Off", label: "Location On/Off" },// no data
        // { id: "candidates-history", name: "Candidates History", label: "Candidates History" },// no data
        // { id: "payment-incomplete", name: "Payment Incomplete", label: "Payment Incomplete" }, // no data
        { id: "screening-needed", name: "Screening Needed", label: "Screeing Needed" },
        // { id: "waitlist-onhold", name: "Waitlist Onhold", label: "Waitlist Onhold" },// no data
        // { id: "candidate-funnel", name: "Candidate Funnel", label: "Candidate Funnel" },
        // { id: "1099k-ddi", name: "1099k DDI Pro Template", label: "1099k DDI Pro Template"},
        // { id: "1099k-walmart-template", name: "1099k Walmart Template", label: "1099k Walmart Template"},
        // { id: "1099nec", name: "1099NEC Template", label: "1099NEC Template" },
        { id: "inactive", name: "Inactive Drivers", label: "Inactive Drivers" },
        { id: "active", name: "Active Drivers", label: "Active Drivers" }, // no data
        // { id: "payment-provider", name: "Payment Provider", label: "Payment Provider" },// not required for ca
        { id: "step-status", name: "Step-Status Summary", label: "Step-Status Summary" }, // no data
        // { id: "candidate-funnel-doc-complete", name: "Candidate Funnel - Doc Complete", label: "Candidate Funnel - Doc Complete" }, // not required for ca
        // { id: "candidate-funnel-signup-complete", name: "Candidate Funnel - Signup Complete", label: "Candidate Funnel - Signup Complete" },
        { id: "insurance-verification", name: "Insurance Verification", label: "Insurance Verification" }, // no data
        // { id: "screening-complete", name: "Screening Complete", label: "Screening Complete" },// no data not required for ca
        { id: "waitlist-candidate-history", name: "Waitlist Candidate History", label: "Waitlist Candidate History" },
        // { id: "screening-consider", name: "Screening Consider", label: "Screening Consider" },// no data
        { id: "client-location-false", name: "Client Location False", label: "Client Location False" },
      ],
    };
  },
  computed: {
    startOfWeek() {
      const startweek =
        6 > DateTime.now().setZone("America/Los_Angeles").weekday ? DateTime.now().setZone("America/Los_Angeles").weekNumber - 1 : DateTime.now().setZone("America/Los_Angeles").weekNumber;
      return DateTime.utc().setZone("America/Los_Angeles").startOf("day").set({ weekNumber: startweek, weekday: 6 });
    },

    startOfMonth() {
      return DateTime.utc().setZone("America/Los_Angeles").set({ day: 1 });
    },

    endOfMonth() {
      const monthEnd = DateTime.utc().setZone("America/Los_Angeles").daysInMonth;
      return DateTime.utc().setZone("America/Los_Angeles").set({ day: monthEnd });
    },
    endOfWeek() {
      return DateTime.fromISO(this.startOfWeek).endOf("day").plus({ days: 6 });
    },

    categoryList() {
      let res = this.category;
      return res.sort((a, b) => a.name.localeCompare(b.name));
    },
  },
  methods: {
    downloadCsv() {
      if (this.selected === "1099k-ddi" || this.selected === "1099k-walmart-template" || this.selected === "1099nec") {
        this.downloadProemplates(this.selected);
      } else if (this.selected === "payment-provider") {
        let param = {
          startDate: this.period.startDate,
          endDate: this.period.endDate,
          reportCatogery: this.selected,
          locationId: this.locationSelected,
        };

        restApi.post("onboard/getReports", encodeWithParam(param)).then((data) => {
          if (data.data.length > 0) {
            downloadFile(data.data, `${this.selected}_[${this.period.startDate}]-[${this.period.endDate}].csv`);
            this.csvLoader = false;
            this.showDownload = true;
          } else {
            this.csvLoader = false;
            this.showDownload = true;
            this.disableBtn = true;
            this.isError = true;
            this.message = "No data available for this period";
          }
        });
      } else {
        this.showDownload = false;
        this.csvLoader = true;
        let param = {
          startDate: this.period.startDate,
          endDate: this.period.endDate,
          reportCatogery: this.selected,
        };
        restApi.post("onboard/getReports", encodeWithParam(param)).then((data) => {
          if (data.data.length > 0) {
            downloadFile(data.data, `${this.selected}_[${this.period.startDate}]-[${this.period.endDate}].csv`);
            this.csvLoader = false;
            this.showDownload = true;
          } else {
            this.csvLoader = false;
            this.showDownload = true;
            this.disableBtn = true;
            this.isError = true;
            this.message = "No data available for this period";
          }
        });
      }
    },

    downloadProemplates(val) {
      this.isError = false;
      this.disabled();
      if (this.roleCheck()) {
        switch (val) {
          case "1099k-ddi":
            restApi.post("tax/taxProTemplate1099k", encodeWithParam({ taxYear: "2022", organization: "DDI" })).then((data) => {
              if (data.data === "") {
                this.isError = true;
                this.message = "No Data Available";
                this.enabled();
              } else {
                downloadFile(data.data, `1099K_DDI_ProTemplate_2022.csv`);
                this.enabled();
              }
            });
            break;
          case "1099k-walmart-template":
            restApi.post("tax/taxProTemplate1099k", encodeWithParam({ taxYear: "2022", organization: "WALMART" })).then((data) => {
              if (data.data === "") {
                this.isError = true;
                this.message = "No Data Available";
                this.enabled();
              } else {
                downloadFile(data.data, `1099K_Walmart_Template_2022.csv`);
                this.enabled();
              }
            });
            break;
          case "1099nec":
            restApi.post("tax/taxProTemplate1099nec", encodeWithParam({ taxYear: "2022", organization: "WALMART" })).then((data) => {
              if (data.data === "") {
                this.isError = true;
                this.message = "No Data Available";
                this.enabled();
              } else {
                downloadFile(data.data, `1099NEC_Walmart_Template_2022.csv`);
                this.enabled();
              }
            });
            break;
        }
      } else {
        this.isError = true;
        this.message = "No Acess to your Role";
        this.enabled();
      }
    },
    format(date) {
      let dateToFormat = date;
      if (typeof date === "string") {
        dateToFormat = DateTime.fromISO(date);
      }
      return dateToFormat.toFormat("MM/dd/yyyy");
    },
    updateStartDate(value) {
      this.period.startDate = value;
      this.validateDates();
    },
    updateEndDate(value) {
      this.period.endDate = value;
      this.validateDates();
    },
    onChange(item) {
      this.isError = false;
      this.selected = item;
      switch (item) {
        case "drivers-approved":
        case "candidates-approved":
        case "eligibility-questions":
        case "location-on/off":
        case "candidates-history":
        case "payment-incomplete":
        case "screening-needed":
        case "waitlist-onhold":
        case "candidate-funnel":
        case "step-status":
        case "candidate-funnel-doc-complete":
        case "candidate-funnel-signup-complete":
        case "insurance-verification":
        case "screening-complete":
        case "waitlist-candidate-history":
        case "screening-consider":
        case "client-location-false":
          this.setDefaultPeriod();
          this.isError = false;
          this.disableBtn = false;
          this.showDriverReports = true;
          this.showFilters = true;
          this.isLocations = false;
          this.showBtn = true;
          break;
        case "inactive":
        case "active":
          this.setMonthPeriod();
          this.isError = false;
          this.disableBtn = false;
          this.showDriverReports = true;
          this.showFilters = true;
          this.isLocations = false;
          this.showBtn = true;
          break;
        case "payment-provider":
          this.showFilters = false;
          this.showBtn = false;
          this.showLocations();
          break;
        case "1099k-ddi":
          this.showDriverReports = true;
          this.showFilters = false;
          this.isLocations = false;
          this.showBtn = true;
          break;
        case "1099k-walmart-template":
          this.showDriverReports = true;
          this.showFilters = false;
          this.isLocations = false;
          this.showBtn = true;
          break;
        case "1099nec":
          this.showDriverReports = true;
          this.showFilters = false;
          this.isLocations = false;
          this.showBtn = true;
          break;
      }
    },

    locationChange(item) {
      this.locationSelected = item;
      this.showDriverReports = true;
      this.showFilters = true;
      this.disableBtn = false;
      this.isError = false;
      this.showBtn = true;
      this.setDefaultPeriod();
    },

    setDefaultPeriod() {
      this.period.startDate = this.format(this.startOfWeek);
      this.period.endDate = this.format(this.endOfWeek);
    },

    setMonthPeriod() {
      this.period.startDate = this.format(this.startOfMonth);
      this.period.endDate = this.format(this.endOfMonth);
    },

    showLocations() {
      this.locationSelected = "";
      restApi.get(`onboard/getOneLocations`).then((data) => {
        this.isLocations = true;
        this.oneLocations = data.data.result;
      });
    },
    invokeQuery() {
      if (this.selected === "drivers-approved") {
        this.getApprovedDrivers();
      } else if (this.selected === "candidates-approved") {
        this.getApprovedCandidates();
      }
    },
    validateDates() {
      let parsedStartDate = Date.parse(this.period.startDate);
      let parsedEndDate = Date.parse(this.period.endDate);
      if (parsedEndDate <= parsedStartDate) {
        this.isError = true;
        this.disableBtn = true;
        this.message = "Please Provide Valid Dates";
      } else {
        this.disableBtn = false;
        this.isError = false;
        this.message = "";
      }
    },
    disabled() {
      this.showDownload = false;
      this.csvLoader = true;
      this.disableBtn = true;
    },
    enabled() {
      this.showDownload = true;
      this.csvLoader = false;
      this.disableBtn = false;
    },
    roleCheck() {
      if (localStorage.getItem("role") === "ACCOUNT_MANAGER" || localStorage.getItem("role") === "SUPERUSER") {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>
