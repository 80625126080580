<template>
  <fragment>
     
    <div class="grid space-between">
      <div class="col-12" v-if="loadingToast">
        <!-- <div class="toast1" >
        <vue-simple-spinner />
      </div> -->
    </div>
       <div class="col-12"  v-else-if="cDisplayToast"><Message class="toast" @close="closeToast" :severity="toastSeverity"> {{toastMessage}}</Message></div>
      <div class="col-6">
        <p class="label">Filters</p>
        <location-filter name="zones" :options="locationsFilterOptions" @onFilter="fetchFilteredCandidates('locations', $event)" />
        <base-filter name="stages" :options="FunnelStages" type="radio" @onFilter="fetchFilteredCandidates('stages', $event)"/>
        <base-filter name="steps" :key="stepRefreshKey" :options="FunnelSteps" type="radio" @onFilter="fetchFilteredCandidates('steps', $event)"/>
        <base-filter name="status" :options="FunnelStatus" type="radio" @onFilter="fetchFilteredCandidates('status', $event)"/>
            <!-- <div class="checkbox-custom">
              <input id="select-all-locations" type="checkbox" @click="selectAllLocations($event)" />
              <label for="select-all-locations"><span class="label-container">Select All Zones</span> </label>
            </div> -->
        <button class="button w-button-primary" @click="fetchFilteredCandidates('search', $event)">Search</button>
      </div>
      <div class="col-6 flex inherit-flex">
        <!-- <div class="col-0.5" style="padding-right:0px" v-if="displayBulkUploadBtn()">
          <button class="button secondary w-button-secondary" @click="openModal('bulk-upload-candidate-modal')">Bulk Upload</button>
        </div> -->
        <div class="col-0.5" style="padding-right:0px" @click="wOpenCommsFlyout('SMS')">
          <img src="@/assets/images/logos/walmart-sms.svg"/>
        </div>
        <div class="col-0.5" style="padding-right:0px" @click="wOpenCommsFlyout('EMAIL')">
          <img src="@/assets/images/logos/walmart-email.svg"/>
        </div>
      <div class="col-6" style="padding-right:0px">
        <div class="search-container">
          <input v-model="searchTerm" class="deposit-search" type="search" placeholder="Applicant Search" title="Candidate: Name, Phone, Email" />
          <button type="submit"><span v-if="searchIcon()" class="icon-x deposit-icon" @click="resetSearch"></span> <span v-else class="icon-search deposit-icon"></span></button>
        </div>
      </div>
      </div>
    </div>
    <div class="onboarding-candidates">
      <div v-if="hasCandidates" style="margin:0px" class="module location-header" :class="{'paginator-align': totalCount > perPage }">
        <div class="checkbox-custom" style="margin:0">
          <input id="select-all" v-model="wSelectAllCandidates" @click="hideToast" type="checkbox"/>
          <label for="select-all"><span class="label-container"><b>Select All ({{ totalCount}} Applicants)</b></span></label>
        </div>
      </div>
      <data-table
        :sticky-header="true"
        :loading="onboardingCandidateViewLoading"
        :headers="headers"
        :entries="filteredCandidates"
        :row-class-function="rowClass"
        :dispaly-row-selector="false"
        :pagination-total-count="totalCount"
        :pagination-per-page="perPage"
        :pagination-current-page="currentPage"
        @onPaginate="fetchData($event)"
        @onChangePerPage="onChangeCount($event)"
      >
        <template #name="{ item }">
          <!-- <a @click="showFullProfile(item.partnerID)">{{ item.name }}</a> -->
          <a @click="openFlyout(item)">{{ item.name }}</a>
        </template>

        <template #action="{ item }">
          <a v-if="item.status !== 'INVALID'">N/A</a>
          <a v-if="item.status == 'INVALID'" @click="deleteCandidate(item.partnerID)">Delete</a>
        </template>
      </data-table>

      <div id="delete-candidate-modal" class="modal" aria-hidden="true">
        <div class="modal-overlay" tabindex="-1" data-micromodal-close="data-micromodal-close">
          <div class="modal-container" role="dialog" aria-modal="true" aria-labelledby="aria-labelledby" style="min-height: 300px;">
            <div style="margin: 15% 0px auto;" v-if="!deletingLoader">
              <vue-simple-spinner />
              <h6 style="text-align: center;padding-top: 10px;">Deleting Please Wait</h6>
            </div>
            <div class="modal-content" style="text-align: center" v-if="deletingLoader">
              <div class="module">
                <span><i class="icon icon-alert-triangle" style="color: #c47d2b; font-size: 60px"></i></span>
              </div>
              <div class="module">
                <h4>Are you sure you want to delete</h4>
              </div>
              <div class="button-group close-top">
                <a class="button secondary" data-micromodal-close>Cancel</a>
                <a class="button secondary" style="background: #c2272c; color: #ffffff; margin-left: 20px" @click="confirmationModal">Delete</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="bulk-upload-candidate-modal" class="modal" aria-hidden="true">
        <div class="modal-overlay" tabindex="-1" data-micromodal-close="data-micromodal-close">
          <div class="modal-container uploadCsv" role="dialog" aria-modal="true" aria-labelledby="aria-labelledby" style="min-height: 530px;">
            <h3>Bulk Upload</h3>
            <p>Please use the standard template for bulk upload of<br>candidates, <a @click="downloadFile">Download Template</a></p>
            <input id="fileUpload" name="csvUpload" ref="fileUpload" type="file" @change="openUploadFile" hidden>
            <div  @drop="dragFile"  class="upload-container">
              <div class="upload-drp-container">
                <img v-if="driverDetails.length == 0" src="@/assets/images/logos/walmart-upload.svg"/>
                <span v-else class="icon-file-text"></span>
              </div>
              <div class="upload-btn-container">
                <p v-if="driverDetails.length == 0"></p>
                <p v-else>{{ uploadedFilename }}</p>
              </div>
              <div class="upload-browse-container">
                <button class="button secondary w-button-secondary" v-if="driverDetails.length == 0" @click="clickupload">Browse</button>
                <button class="button secondary w-button-secondary" v-else @click="clickupload">Browse again</button>
                <!-- <input class="" id="fileUpload" ref="fileUpload" type="file" name="csvUpload" value="Browse" @change="openUploadFile" /> -->
              </div>
              <div class="upload-warning-container">
                <img src="@/assets/images/logos/walmart-note.svg"/>
                <span class="warningText">  Supported file formats are xls , xlsx files only of 5MB max.</span>
              </div>
            </div>
            <transition name="fade">
                <p v-show="invalidData" class="invalidFileClass">
                  <span class="icon-alert-triangle" style="padding-right: 10px;"></span>
                  Missing the required details to proceed. Please try uploading again with all the information in place.
                </p>
                
            </transition>
            <transition name="fade">
                <p v-show="walmartEmail" class="invalidFileClass">
                  <span class="icon-alert-triangle" style="padding-right: 10px;"></span>
                  You cannot proceed with Walmart email ID, Please delete the row containing walmart email IDs to proceed.
                </p>
            </transition>
            <transition name="fade">
                <p v-show="invalidFile" class=" invalidFileClass">
                  <span class="icon-alert-triangle" style="padding-right: 10px;"></span>
                  Please upload file with correct file format. Supported file formates are xls, xlsx
                </p>
            </transition>
            <div class="table-container" v-if="driverDetails.length > 0">
              <p class="record-count">Preview ({{ driverDetails.length }} records found)</p>
              <table class="driverTable">
                        <tr>
                          <th>First Name</th>
                          <th>Last Name</th>  
                          <th>Market Name</th>  
                          <th>Email</th>
                          <th>DOB</th>
                          <th>Phone No</th>
                          <th>Address Line 1</th>
                          <th>Address Line 2</th>
                          <th>Address Line 3</th>
                          <th>Driver License</th>
                          <th>Driver License Expiry</th>
                          <th>Vehicle Company</th>
                          <th>Vehicle Model</th>
                          <th>Vehicle Color</th>
                          <th>Vehicle Year</th>
                          <th>Vehicle Registration</th>
                          <th>City</th>
                          <th>State</th>
                          <th>Zip Code</th>
                          <th>GST/HST</th>
                          <th></th>
                        </tr>
                        <tr v-for="rows in driverDetails" :key="rows.id" v-bind:class = "(rows.isError == true)? 'errorClass':'validClass'">
                          <td style="color:blue">{{ rows.First_Name }}</td>
                          <td style="color: blue;">{{ rows.Last_Name }}</td>
                          <td>{{ rows.Market_Name }}</td>
                          <td>{{ rows.Email }}</td>
                          <td>{{ rows.DOB }}</td>
                          <td>{{ rows.Phone_Number}}</td>
                          <td>{{ rows.Address_Line1 }}</td>
                          <td>{{ rows.Address_Line2 }}</td>
                          <td>{{ rows.Address_Line3 }}</td>
                          <td>{{ rows.Driver_License }}</td>
                          <td>{{ rows.Driver_License_Expiry }}</td>
                          <td>{{ rows.Vehicle_Company }}</td>
                          <td>{{ rows.Vehicle_Model }}</td>
                          <td>{{ rows.Vehicle_Color }}</td>
                          <td>{{ rows.Vehicle_Year }}</td>
                          <td>{{ rows.Vehicle_Registration }}</td>
                          <td>{{ rows.City }}</td>
                          <td>{{ rows.State }}</td>
                          <td>{{ rows.Zip_Code }}</td>
                          <td>{{ rows['GST/HST'] }}</td>
                          <td @click="deleteRow(rows.Email)"><i class="icon-trash-2"></i></td>
                        </tr>
                        
              </table>
                  </div>
            <div class="upload-cancel-btn">
              <button v-if="driverDetails.length > 0" :disabled="walmartEmail || requiredFlag" :class="walmartEmail ? 'button disable' : 'button'" @click="uploadBulkCSV()">Submit</button>
              <span style="padding-left: 10px;">
                <button class="button secondary w-button-secondary" @click="hidePopUp">Cancel</button>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div id="upload-progress-model" class="modal" aria-hidden="true">
        
        <div class="modal-overlay" tabindex="-1" data-micromodal-close="data-micromodal-close">
          
          <div class="modal-container" role="dialog" aria-modal="true" aria-labelledby="aria-labelledby" style="min-height: 300px;">
            <h3>Uploading...</h3>
            <span class="upload-progress-container">
              
              <span class="icon-file-text"></span>
              <p>{{ uploadedFilename }}</p>
              <ProgressBar mode="indeterminate" style="height: 5px;width: 550px;"></ProgressBar>
              <p>Loading in progress</p>
            </span>
          </div>
        </div>
      </div>
    </div>
    <bulk-communications-flyout 
      :bulk-obj="candidateObj" 
      :on-close="closeBulkComms" 
      :display-editor="displayEmailEditor" 
      :comms-type="commsFlyoutType" 
      @sendText="sendSMS" 
      @sendEmail="sendEmail" 
      />
    <candidate-flyout 
                    :candidate="candidate" 
                    :eligibility-questions="eligibilityQuestions"  
                    :partner-application="partnerApplication" 
                    :personal-details="personalDetails" 
                    :on-close="closeComms" 
                    :cid="cid"
                    :loading="loadingToast"
                    @refresh="refresh" 
                    @onRemoveInsurance="removeInsuranceImage"
                    @insuranceImageUploaded="insuranceImageUploaded"
                    @invalidateCandidate="refetchFlyout"
                    @refetchPII="getPersonalDetails"
      />
    
  </fragment>
</template>
<script>
import { Fragment } from "vue-fragment";
import {
  BaseFilter,
  DataTable,
  LocationFilter,
  TableHeader
} from "@/components";
import { mapActions, mapGetters } from "vuex";
import CandidateFlyout from "@/modules/admin/onboarding/flyouts/CandidateFlyout";
import { DateTime } from "luxon";
import { OnboardingStatus } from "@/modules/admin/partners/model";
import { BulkCommunicationsFlyout } from "@/modules/shared/communications";
import micromodal from "micromodal";
import VueSimpleSpinner from "vue-simple-spinner";
import { restApi } from "@/http/http.config";
import {encodeWithParam} from "@/util/Base64Encoding";
import Message from 'primevue/message';
import {FunnelProgress,FunnelStatus} from "@/util/funnelProgress";
import { decode, decrypt } from "@/filters";
import { convertBufferToContent } from "@/util/BufferContentConverter";
import axios from 'axios'
import XLSX from "xlsx";
import ProgressBar from 'primevue/progressbar';
import { formatDateYYYYMMDD } from "@/filters";
export default {
  components: {
    CandidateFlyout,
    Fragment,
    BaseFilter,
    LocationFilter,
    DataTable,
    VueSimpleSpinner,
    BulkCommunicationsFlyout,
    Message,
    ProgressBar
  },
  data: () => ({
    uploadedFilename: "",
    invalidFile: false,
    invalidData: false,
    driverDetails: [],
    fp: new FunnelProgress(),
    selectedStage: "",
    selectedSteps: [],
    selectedStep:"",
    selectedStatus:[],
    cid:"",
    displayEmailEditor: true,
    deletingLoader:true,
    selectedLocation: "",
    candidate: {},
    searchTerm: "",
    deleteCandidateId: "",
    currentCandidate: "",
    selectedStages: [],
    selectedLocations: [],
    selectAllLocationsFlag:false,
    selectedCandidate: {},
    locations: [],
    displayBulkMenu: false,
    candidateObj: [],
    disablebtn: true,
    singleChecks: [],
    bulkActionPartners: [],
    bulkActionCount: 0,
    totalCount: 0,
    currentPage: 0,
    perPage: 50,
    offset: 0,
    updating: 0,
    startDayWeek: 6,
    allCandidates: false,
    isFiltered: false,
    isLoading: true,
    isSubscriptionLoading: true,
    onboardingCandidateView: [],
    onboardingCandidateViewLoading: false,
    partnerSteps: [],
    eligibilityQuestions: [],
    partnerApplication: [],
    personalDetails: {},
    wSelectAllCandidates: false,
    commsFlyoutType: '',
    toastMessage: '',
    displayToast: false,
    toastSeverity:'',
    clickedStatus:"",
    clickedStep:"",
    cancelCandidateViewSource:null,
    stepRefreshKey:1000,
    loadingToast:false,
    walmartEmail:false,
    requiredFlag:false
  }),
  computed: {
    ...mapGetters(["isFlyoutShowing", "getSelectedEmployee","getUserID","getLocationFilterData","getAllLocationsData"]),
    hash() {
      return this.$route.hash === "#candidates";
    },
    cDisplayToast() {
      return this.displayToast;
    },
    FunnelStages () {
      return this.fp.getAllStages()
    },
    FunnelSteps() {
      return this.selectedSteps;
    },
    FunnelStatus () {
      return this.selectedStatus;
    },
    locationsFilterOptions() {
      return [...this.$store.state.onboarding.candidatesTab.locationsFilterOptions];
    },
    filteredCandidates() {
      const candidates = this.onboardingCandidateView.map((candidate) => {
        let timeInStep = "";
        const timeDifference = DateTime.utc().diff(DateTime.fromISO(candidate.date), ["minutes", "hours", "days"]);
        timeInStep =
          timeDifference.values.days > 0
            ? `${timeDifference.values.days}d`
            : timeDifference.values.hours > 0
            ? `${Math.round(timeDifference.values.hours)}h`
            : `${Math.round(timeDifference.values.minutes)}m`;
        return {
          id: candidate.partnerLocationID,
          partnerID: candidate.partnerID,
          name: `${candidate.firstName || ""} ${candidate.lastName || ""}`,
          location: candidate.location,
          locationID: candidate.locationID,
          email: candidate?.email ? candidate?.email : "",
          phone: candidate?.phone ? candidate?.phone : "",
          time: timeInStep,
          date: candidate.date,
          stage: candidate.stage ? candidate.stage : "",
          step: candidate.step ? candidate.step :"",
          status: candidate.status,
          alertTime: candidate.alertTime ? candidate.alertTime : null,
          userID: candidate?.partnerID ? candidate.partnerID : "",
          os: candidate?.assignedOS
        };
      });
      return candidates
    },
    hasCandidates() {
      return this.filteredCandidates && this.filteredCandidates.length > 0;
    },
    headers() {
      return [
        new TableHeader({ cellClass: "bulk-checkbox-actions", name: "checkbox" }),
        new TableHeader({
          name: "name",
          label: "Applicant Name",
          cellContents: "name",
          headerClass: "cell20",
          sortable: true,
        }),
        new TableHeader({ name: "phone", label: "Phone", cellContents: "phone", headerClass: "cell5", sortable: false }),
        new TableHeader({
          name: "email",
          label: "Email",
          cellContents: "email",
          headerClass: "cell10",
          sortable: true,
        }),
        new TableHeader({
          name: "location",
          label: "Zone",
          cellContents: "location",
          headerClass: "cell20",
          sortable: true,
        }),
        new TableHeader({
          name: "time",
          label: "Time In",
          cellContents: "time",
          headerClass: "cell10",
          sortable: false,
          cellClass: "time",
        }),
        new TableHeader({
          name: "stage",
          label: "Stage",
          cellContents: (item) => this.getStageLabel(item.stage),
          headerClass: "cell15",
          sortable: false,
          sorted: false,
          cellClass: "stage",
        }),
        new TableHeader({ 
          name: "step", 
          label: "Step", 
          cellContents: (item) =>this.getStepLabel(item.step), 
          headerClass: "cell15", 
          sortable: false,
          sorted: false, 
          cellClass: "step" 
        }),
        new TableHeader({
          name: "status",
          label: "Status",
          headerClass: "cell20",
          cellContents: (item) => this.getStatusLabel(item.status),
          sortable: false,
         // sortFunction: this.sortStatus,
          sorted: false,
         // direction: Direction.DESC,
          cellClass: "status",
        }),
        new TableHeader({ name: "os", label: "OA", cellContents: "os", headerClass: "cell45", sortable: false, cellClass: "name" }),
      ];
    },
    getRole() {
      return localStorage.getItem("role");
    }
  },
  watch: {
    isFlyoutShowing(newData) {
      if (newData === false) {
        this.candidate = {};
      }
    },
    getSelectedEmployee(newData, oldData) {
      if (newData.name !== oldData.name) {
        this.displayBulkMenu = false;
      }
      if (this.$refs.stageFilter) {
        this.$refs.stageFilter.reset();
      }
    },
    searchTerm(newData, oldData) {
      if (newData != oldData) {
        this.newOnboardingCandidateView();
      }
    }
  },
  methods: {
    ...mapActions(["showFlyout","updateSelectedLocations"]),
    showFullProfile(partnerID) {
      // console.log(partnerID)
      this.$router.push({ name: "partner", params: { id: partnerID } });
    },

  async uploadBulkCSV() {

      let obj = {clientID:process.env.VUE_APP_CLIENT_ID,driverDetails:this.driverDetails}
      micromodal.close('bulk-upload-candidate-modal');
      micromodal.show('upload-progress-model')
      await restApi.post("/ca/uploadBulkCSV",encodeWithParam(obj)).then(()=>{
        this.invalidData = false;
        this.driverDetails = [];
        micromodal.close('upload-progress-model')
      })

    },
    displayBulkUploadBtn() {
      return localStorage.getItem("role") ==='ONBOARDING_SPECIALIST','SUPERUSER','ACCOUNT_MANAGER' && localStorage.getItem("country") === 'canada' ? true : false;
      // return true;
    },
    openModal(id) {
      micromodal.show(id);
    },
    clickupload(){
      this.driverDetails = [];
      document.getElementById("fileUpload").value = "";
      this.invalidFile = false;
      this.invalidData = false;
      this.walmartEmail = false;
      this.requiredFlag = false;
      this.$refs.fileUpload.click();
    },
     validations(obj){
      obj.forEach((item,index) => { 
        console.log(index)
        item['remarks'] = [];
      if(this.isRequired(item.First_Name)){
              item['First_Name'] = "";
              item['isError'] = true; 
              item['remarks'].push("First Name is required");
              this.requiredFlag = true;
            }
            if(this.isRequired(item.Last_Name)){
              item['Last_Name'] = "";
              item['isError'] = true; 
              item['remarks'].push("Last Name is required");
              this.requiredFlag = true;
            }
            if(this.isRequired(item.Email)){
              item['Email'] = "";
              item['isError'] = true; 
              item['remarks'].push("Email is required");
              this.requiredFlag = true;
            }else{
              item.Email = item.Email.toLowerCase();
            }
            if(this.isRequired(item.Phone_Number)){
              item['Phone_Number'] = "";
              item['isError'] = true; 
              item['remarks'].push("Phone number should be in E.164 format and have max should be of 12 digits");
              this.requiredFlag = true;
            }
            if(this.isRequired(item.Market_Name)){
              item['Market_Name'] = "";
              item['isError'] = true; 
              item['remarks'].push("Zone is required");
              this.requiredFlag = true;
            }
            if(this.isRequired(item.DOB)){
              item['DOB'] = "";
              item['isError'] = true; 
              item['remarks'].push("DOB is required");
              this.requiredFlag = true;
            }
            if(this.isRequired(item.Driver_License)){
              item['Driver_License'] = "";
              item['isError'] = true; 
              item['remarks'].push("Driver License is required");
              this.requiredFlag = true;
            }else{
              item['Driver_License'] = item.Driver_License.replaceAll('-','');
              console.log(item['Driver_License'], "DLllllllllll");
            }
            if(this.isRequired(item.Driver_License_Expiry)){
              item['Driver_License_Expiry'] = "";
              item['isError'] = true; 
              item['remarks'].push("Driver License Expiry is required");
              this.requiredFlag = true;
            }
            if(this.isRequired(item.Address_Line1)){
              item['Address_Line1'] = "";
              item['isError'] = true; 
              item['remarks'].push("Address Line 1 is required");
              this.requiredFlag = true;
            }
            if(this.isRequired(item.City)){
              item['City'] = "";
              item['isError'] = true;
              item['remarks'].push("City is required");
              this.requiredFlag = true;
            }
            if(this.isRequired(item.State)){
              item['State']="",
              item['isError']=true;
              item['remarks'].push("State is required");
              this.requiredFlag = true;
            }
            if(this.isRequired(item.Vehicle_Registration)){
              item['Vehicle_Registration']="",
              item['isError']=true;
              item['remarks'].push("Vehicle_Registration is required");
              this.requiredFlag = true;
            }
            if(this.isRequired(item['GST/HST'])){
              item['GST/HST']="",
              item['isError']=true;
              item['remarks'].push("GST/HST is required")
            }
            if(this.isRequired(item.Zip_Code)){
              item['Zip_code']='';
              item['isError']=true;
              item['remarks'].push("Zip code is required");
              this.requiredFlag = true;
            }
            if(typeof item.Last_Name != "undefined"){
              if(item.Last_Name.length < 2){
                item['isError'] = true;
                this.invalidData = true;
                item['remarks'].push("Minimun length of last name should be 2 as per DMS validation constraint")
              }
            }
            if(this.isRequired(item.ACTION)){
              item['ACTION']='';
              item['isError']=true;
              item['remarks'].push("Action item should not be empty");
            }
            console.log("test push")
            const regex = /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/i;
            const specialRegex =/^(?!-)[A-Za-z0-9]+(-[A-Za-z0-9]+)*$/gm;
            const dateRegex =/^\d{4}-\d{2}-\d{2}$/;
            const cityRegex =/^[A-Za-z\-\s!]*$/gm;
            const letterSpaceRegex  =/^[A-Za-z\s]+$/gm;
            const phoneRegex = /^\+1\d{10}$/gm;
            const emailRegex =/^[A-Za-z0-9!#$%^&*().+\-=?^_`{|}]+@([A-Za-z]+)\.com$/;
            if(typeof item.Email != "undefined"){
              if(!emailRegex.test(item.Email.trim())){
                item['isError']= true;
                this.invalidData = true;
                item['remarks'].push("Invalid Email format")
              }
            }
            if(typeof item.Email != "undefined"){
              if(item.Email.includes('@walmart')){
                this.walmartEmail = true;
                item['isError'] = true
              }
            }
            
            if(typeof item.Zip_Code != "undefined"){
              if(!regex.test(item.Zip_Code.trim())){
                item['isError']= true;
                this.invalidData = true;
                item['remarks'].push("Invalid Zip code")
              }
            }
            if(typeof item.Driver_License != "undefined"){
              if(!specialRegex.test(item.Driver_License.trim())){
                item['isError'] = true;
                this.invalidData=true;
                item['remarks'].push("driver license should not allow special characters other than hyphen")
              }
            }
            if(typeof item.City != "undefined"){
              if(!cityRegex.test(item.City)){
                item['isError'] = true;
                this.invalidData = true;
                item['remarks'].push('city can only contain letters and - and ! ')
              }
            }
            if(typeof item.State != "undefined"){
              if(!letterSpaceRegex.test(item.State.trim())){
                item['isError']=true;
                this.invalidData=true;
                item['remarks'].push('State can only contain letters and spaces')
              }
            }
            if(typeof item.Driver_License_Expiry != "undefined"){
              if(!dateRegex.test(item.Driver_License_Expiry.trim())){
                item['isError'] = true;
                this.invalidData= true;
                item['remarks'].push("DL Expiry format should be yyyy-mm-dd")
              }else if(item.Driver_License_Expiry){
                if(!this.checkLicenseDate(item.Driver_License_Expiry.trim())){
                  item['isError'] = true;
                this.invalidData= true;
                item['remarks'].push("Should have an drivers license expiry date at least three weeks in the future")
                }
                 
              }
            }
            if(typeof item.DOB != "undefined"){
              if(!dateRegex.test(item.DOB)){
                item['isError']=true;
                item['remarks'].push("DOB should be of format YYYY-MM-DD")
              }
            }

// console.log(this.requiredFlag, "fkahgg")
            if(typeof item.Phone_Number != "undefined"){
              if(!phoneRegex.test(item.Phone_Number.trim())){
                item['isError'] = true;
                this.invalidData=true;
                item['remarks'].push("Phone number should be in E.164 format and have max should be of 12 digits")
              }
            }
            if(item.DOB =='NaN-NaN-NaN'){
              item['isError'] = true;
              this.invalidData= true;
              item['remarks'].push("DOB is required")
            }else if(item.DOB){
              let age = this.checkAge(item.DOB);
              if(age < 18){
                item['isError'] = true;
              this.invalidData= true;
              item['remarks'].push("Minimum age should be 18 years")
              }
            }   
            
            
    })
    
    this.driverDetails = obj;
    console.log(this.driverDetails)

  },
  checkAge(dob){
    var birthDate = new Date(dob);
	 var difference=Date.now() - birthDate.getTime(); 
	 var  ageDate = new Date(difference); 
	 var calculatedAge=   Math.abs(ageDate.getUTCFullYear() - 1970);
	 return calculatedAge;
  },
  checkLicenseDate(licenseDate){
    // console.log(licenseDate, "enter by user")
    let numberOfWeeks = 3;
    let dateObj = new Date();
    dateObj.setDate(dateObj.getDate() + numberOfWeeks * 7);
    let modifiedDate = formatDateYYYYMMDD(dateObj);
    if(modifiedDate <= licenseDate){
      return true;
    }else{
      return false;
    }
  },
  isRequired(element){
          let isError = false;
          if(typeof element == 'undefined'){
            isError = true;
            this.invalidData=true;
            return isError;
          }else{
            return isError;
          }
        },
  showErrorMessage(){
    this.invalidData = false;
    this.walmartEmail = false;
    this.driverDetails.map((index) => { 
        if(index.isError == true && !index.Email.includes('@walmart')){ 


          this.invalidData = true;
          
        }else{
          this.requiredFlag = false;
        }
        if(index.Email.includes('@walmart')){
          this.walmartEmail = true;
        }
        
      })
  },
    openUploadFile(e) {
      const that = this;
      var files = e.target.files,
      f = files[0];
      this.uploadedFilename = files[0].name;  
      let fileName = files[0].name.split(".");
      let ext = fileName[fileName.length - 1];
      if (ext == "xlsx" || ext == "xls") {
        this.invalidFile = false;
        var reader = new FileReader();
        reader.onload = function (e) {
        that.loading = false;
        var data = new Uint8Array(e.target.result);
        var workbook = XLSX.read(data, { type: "array",cellDates: true,cellNF: true,cellText: true });
        let sheetName = workbook.SheetNames[0];
        let worksheet = workbook.Sheets[sheetName];
        const options = {
        raw: false, 
        // dateNF: 'yyyy-mm-dd', // Specify the date format string here
      };
        let obj = XLSX.utils.sheet_to_json(worksheet,options);
        obj = obj.map((item) => {
          // item.DOB = formatDateYYYYMMDD(item.DOB);
          // item.Driver_License_Expiry = formatDateYYYYMMDD(item.Driver_License_Expiry)
        item.isError = "false";
        return item;
      })
      // console.log(obj)
        that.validations(obj);

      };
        reader.readAsArrayBuffer(f);
      } else {
        this.invalidFile = true;
    }
        
    },
    // afterRead:function(){
    //         console.log(this.fileinput)
    //         // write your logic for import here
    //     },

        deleteRow(email){ 
          this.driverDetails = this.driverDetails.filter(function(e) {  
            return e.Email !== email
          })
          this.showErrorMessage()
        },

        hidePopUp(){
          this.driverDetails = [];
          document.getElementById("fileUpload").value = "";
          this.invalidFile = false;
          this.invalidData = false;
          micromodal.close("bulk-upload-candidate-modal");
        },

        downloadFile () {
            const url = '/files/ca_candidate_template.xlsx';
            window.location.href = url;
        },

    dragFile(e) {
      e.preventDefault();
        // console.log("dfsd",e.dataTransfer.files);
      },
    searchIcon() {
      return this.searchTerm !== "";
    },
    hideToast() {
      if(!this.wSelectAllCandidates) {
        this.displayToast = false;
      }
    },
    closeToast() {
      this.displayToast=false;
    },
    resetSearch() {
      this.searchTerm = "";
      this.currentPage = 1;
      this.newOnboardingCandidateView();
    },
    resetPaginator() {
      this.currentPage = 1;
      this.offset = 0;
    },
    async openFlyout(candidate) {
      this.selectedCandidate = {};
      if (candidate) {
        this.$log.info("Opening flyout for candidate with ID", candidate.id);
        this.selectedCandidate = this.onboardingCandidateView.filter((origCandidate) => origCandidate.partnerLocationID === candidate.id)[0];
        this.cid=this.selectedCandidate.partnerLocationID;
        this.getCandidateDetails(this.selectedCandidate.partnerID);
        
        this.showFlyout("candidate-flyout");
      }
    },
    refetchFlyout() {
      this.getCandidateDetails(this.selectedCandidate.partnerLocationID);
      this.showFlyout("candidate-flyout");
    },
    getPartnerSteps(id) {
        restApi.post('onboard/getFunnelProgress',encodeWithParam({candidateID: id})).then((data) =>{
        this.partnerSteps = data.data.result.map((partnerStep) => {
            return {
              id: partnerStep.id,
              step: partnerStep.name,
              displayName: partnerStep.description,
              stage: partnerStep.stage,
              status: partnerStep.candidateStatus,
              date: partnerStep.date,
              stepDetail: partnerStep.candidateStatusDetail,
            };
          });
  })
    },
    getCandidateDetails(id) {
      this.loadingToast = true;
      return restApi.post('onboard/getCandidateDetails',encodeWithParam({candidateID: id})).then(async (data)=>{
          if (data) {
          const candidate = data.data;
          const candidateProgress = candidate.candidateProgress.length > 0 ? candidate.candidateProgress[0] : null;
          let timeInStep = "";
          if (candidateProgress) {
            const timeDifference = DateTime.utc().diff(DateTime.fromISO(candidateProgress.date), ["minutes", "hours", "days"]);
            timeInStep =
              timeDifference.values.days > 0
                ? `${timeDifference.values.days}d`
                : timeDifference.values.hours > 0
                ? `${Math.round(timeDifference.values.hours)}h`
                : `${Math.round(timeDifference.values.minutes)}m`;
          }
          this.currentCandidate = "";
          this.candidate = {
            id: candidate.id,
            electronicSignature: candidate.electronicSignature,
            partnerID: candidate.partner.id,
            name: `${candidate.partner.firstName || ""} ${candidate.partner.lastName || ""}`.trim(),
            location: candidate.location.name,
            locationID: candidate.location.id,
            locationCountCurrent: candidate.location.stageCounts?.length > 0 ? await this.approvedCount(candidate.location.stageCounts) : 0,
            locationCountTotal: candidate.location.headCountRequests?.length > 0 ? await this.totalApprovedCount(candidate.location.headCountRequests) : 0,
            email: candidate.partner.email,
            communication_email: candidate.partner.communication_email,
            phone: candidate.partner.phone,
            time: timeInStep,
            stage: candidateProgress ? candidateProgress.step.stage : "",
            step: candidateProgress ? candidateProgress.step.name : "",
            status: candidateProgress ? candidateProgress.candidateStatus : "",
            candidateReason: candidateProgress.candidateStatusDetail ? candidateProgress.candidateStatusDetail : "",
            candidateProgressId: candidateProgress.id,
            alertTime: candidateProgress ? candidateProgress.alertTime : null,
            specialist: candidate?.location?.teamSpecialist ? this.computeEmployeeName(candidate.location.teamSpecialist.employee) : "",
            userID: candidate?.partner?.userID ? candidate.partner.userID : "",
            externalID: candidate?.partner?.ids[0]?.externalID ? candidate.partner.ids[0].externalID : null,
            username: candidate?.partner?.user?.username,
            userId: candidate?.partner?.user?.id,
            approvalDate: candidate?.approvalDate ? candidate.approvalDate : "",
            notes: candidate.notes,
            notesList: candidate.notesList,
            clientID: candidate?.location?.client?.id,
            insuranceExpiry: candidate.partner.insuranceExpiry ? candidate.partner.insuranceExpiry : ""
          };
          const checkDocumentsComplete = await restApi.post('onboard/getFunnelProgress',encodeWithParam({candidateID: this.candidate.id}));
          const documentComplete = checkDocumentsComplete.data.result.filter((item)=>item.candidateStatus === FunnelStatus.DOCUMENTS_COMPLETE)
          if(documentComplete.length > 0) {
            this.getDocuments()
          }
          this.currentCandidate = this.candidate.id;
          this.getFunnelQuestions(this.candidate?.partnerID,this.candidate?.locationID);
          this.getPersonalDetails(this.candidate.id)
          // this.insuranceImageUploaded();
          this.loadingToast = false;
        }  
        
        })
    },
    insuranceImageUploaded() {
      this.getDocuments();
    },
    getDocuments() {
      restApi.post('/onboard/getDocuments',encodeWithParam({
        clientLocationPartnerID: this.candidate.id,
        clientID: this.candidate.clientID,
        clientLocationID: this.candidate.locationID,
        userID: this.candidate.userID
      })).then((docs) => {
        
        let insuranceFileDetails = docs.data.result.filter((item)=>item.title.toLowerCase() === "insurance");
        restApi.post('/employee/getUploadedFile',encodeWithParam({fileID: insuranceFileDetails[0].fileID})).then((data)=>{
          let imageContent = data.data.bufferInHex ? convertBufferToContent(data.data.bufferInHex,data.data.contentType) : null
          this.candidate = {...this.candidate,allDocs:docs.data.result,insuranceImageURL: imageContent};
          this.loadingToast = false;
        })
      })
    },
    removeInsuranceImage() {
      this.candidate.insuranceImageURL =null;
      this.candidate.insuranceExpiry=null;
    },
    getFunnelQuestions(partnerId,locationId) {
      restApi.post('/onboard/getFunnelQuestions',encodeWithParam({partnerID:partnerId,clientLocationID:locationId})).then((data)=>{
        this.eligibilityQuestions =data.data.eligibilityQuestions;
        this.partnerApplication = data.data.eligibilityAnswers;
      })
    },
    getFunnelAnswers(parnerId) {
      restApi.post('/onboard/getFunnelAnswers',encodeWithParam({partnerID: parnerId})).then((data)=>{
        this.partnerApplication = data.data?.result
      })
    },
    getPersonalDetails(id) {
      restApi.post('/onboard/getPersonalDetails',encodeWithParam({candidateID: id})).then(async(data)=>{
            const decodedSsn = decode(data.data.personalDetails?.encodedData)
            const decryptedSnn = await decrypt(decodedSsn.dssn)
            this.personalDetails = {
              ssn: '*****'+decryptedSnn?.substring(5),
              ein: data.data.personalDetails.ein,
              fullSsn: data.data.personalDetails.ssn ? data.data.personalDetails.ssn : null,
              fullEin: data.data.personalDetails.ein ? data.data.personalDetails.ein : null,
              dob: data?.data?.personalDetails?.dateOfBirth ? data.data.personalDetails.dateOfBirth:"",
              licenseNumber: data.data?.driversLicenses?.number ? data.data?.driversLicenses?.number:"",
              licenseExpiry: data?.data?.driversLicenses?.expiration ? data.data.driversLicenses.expiration:"",
              licenseState: data.data.driversLicenses.state ? data.data.driversLicenses.state:"",
              insuranceExpiry: data.data.personalDetails.insuranceExpiry ? data.data.personalDetails.insuranceExpiry:"",
              streetAddress: data.data.personalDetails.address1 ? data.data.personalDetails.address1:"",
              address2:data.data.personalDetails.address2 ? data.data.personalDetails.address2:"",
              city: data.data.personalDetails.city ? data.data.personalDetails.city:"",
              state: data.data.personalDetails.state ? data.data.personalDetails.state:"",
              zip: data.data.personalDetails.postalCode ?data.data.personalDetails.postalCode:"",
              wasConvicted: data.data.criminalHistory?.wasConvicted ? data.data.criminalHistory.wasConvicted:false,
              convictionDescription: data.data.criminalHistory?.convictionDescription ? data.data.criminalHistory.convictionDescription:"",
              hasPendingCharges: data.data.criminalHistory?.hasPendingCharges ? data.data.criminalHistory.hasPendingCharges:false,
              pendingChargesDescription: data.data.criminalHistory?.pendingChargesDescription ? data.data.criminalHistory.pendingChargesDescription:"",
              isSexOffender: data.data.criminalHistory?.isSexOffender ? data.data.criminalHistory.isSexOffender:false,
              stateRegisteredYear: data.data.criminalHistory?.stateRegisteredYear ? data.data.criminalHistory.stateRegisteredYear:"",
              stateRegistered: data.data.criminalHistory?.stateRegistered ?data.data.criminalHistory.stateRegistered:"",
              countyRegistered: data.data.criminalHistory?.countyRegistered ? data.data.criminalHistory.countyRegistered:"",
              decoded: data.data.personalDetails.encodedData ? decode(data.data.personalDetails.encodedData) : "",
              vehicle_color: data.data.personalDetails.vcolor ? data.data.personalDetails.vcolor:"",
              vehicle_model: data.data.personalDetails.model ? data.data.personalDetails.model:"",
              vehicle_year: data.data.personalDetails.vyear ? data.data.personalDetails.vyear:"",
              vehicle_make: data.data.personalDetails.make ? data.data.personalDetails.make:"",
              vehicle_plate: data.data.personalDetails.vplate ? data.data.personalDetails.vplate:"",

            };
            this.personalDetails.decoded.dssn = await decrypt(this.personalDetails.decoded.dssn)
      })
    },
        maskNumber(value){
      if(value){
        if(value.length < 8){
          return `****${value.replace(/\d(?=\d{4})/g, "*")}`
        }
        else{
          return value.replace(/\d(?=\d{4})/g, "*");
        }
        
      }
      else{
        return "";
      }
      
    },
    getStageLabel(stageName) {
      return stageName ? this.fp.getStageLabel(stageName): "Unknown Stage";
    },
    getStepLabel(stepName) {
      return stepName ? this.fp.getStepLabel(stepName): "Unknown Step"
    },
    getStatusLabel(statusName) {
      return statusName ? this.fp.getStatusLabel(statusName) : "Unknown Status"
    },
    rowClass(candidate) {
      if (OnboardingStatus.isWithdrawn(candidate.status)) {
        return "withdrawn";
      }
      if (OnboardingStatus.isWarningCandidateStatus(candidate.status)) {
        return "status-alert";
      }
      if (candidate.alertTime && DateTime.fromISO(candidate.alertTime) < DateTime.utc()) {
        return "time-alert";
      }
      return null;
    },
    sortStage() {
      return (candidate1, candidate2) => {
        const candidate1Urgency = this.computeStage(candidate1);
        const candidate2Urgency = this.computeStage(candidate2);
        return candidate1Urgency - candidate2Urgency;
      };
    },
    computeStage(candidate) {
      return this.fp.getStageID(candidate.stage);
    },
    computeEmployeeName(employee) {
      if (employee) {
        return `${employee.firstName} ${employee.lastName}`.trim();
      }
      return "none";
    },
    fetchData(event) {
      this.currentPage = Number(event.pageNumber);
      this.offset = event.offset;
      this.newOnboardingCandidateView();
    },
    onChangeCount(event) {
      this.perPage = event.perPage;
    },
    fetchFilteredCandidates(type, event) {
      switch(type) {
        case "stages":
          this.stepRefreshKey++;
          this.clickedStep = "";
          this.clickedStatus = "";
          this.setFunnelSteps(event);
          this.setFunnelStatus(event);
          break;
        case "steps":
          this.statusRefreshKey++;
          event.length ? this.clickedStep = event[1].name: this.clickedStep = "";
          this.setFunnelStatus(event);
          break;
        case "status":
          event.length ? this.clickedStatus = event[1].name: this.clickedStatus = "";
          break;
        case "locations":
          this.updateSelectedLocations(event);
          this.selectedLocations = event
          break;
        case "search":
          if (this.selectedLocations.length === 0) {
            this.selectAllLocationsFlag=true;
          } else {
            this.selectAllLocationsFlag=false;
          }
          this.newOnboardingCandidateView();
          break;
        default:
          break;
      }
      this.resetPaginator();
    },
    selectCandidate() {
      this.allCandidates = false;
      if (this.singleChecks.length > 0) {
        this.disablebtn = false;
      } else {
        this.disablebtn = true;
      }
      if (this.bulkActionPartners.length == this.singleChecks.length) {
        this.allCandidates = true;
      }
      this.bulkActionCount = this.singleChecks.length;
    },
    createBulkObj(data) {
      this.bulkActionPartners = data.map((partner) => {
        return {
          name: `${partner.firstName} ${partner.lastName}`.trim(),
          email: partner.email,
          phone: partner.phone ? partner.phone : "",
          locations: [partner.location],
          partnerID: partner.partnerID,
          userID: this.getSelectedEmployee.id,
          externalID: partner.externalID ? partner.externalID : null,
        };
      });
      this.isLoading = false;
    },
    sendMessage() {
      this.displayEmailEditor = true;
      this.candidateObj = [];
      this.bulkActionPartners.forEach((item) => {
        if (this.singleChecks.includes(item.partnerID)) {
          let customObj = {
            name: item?.name ? item.name : "",
            email: item?.email ? item.email : "",
            partnerID: item?.partnerID ? item.partnerID : "",
            externalID: item?.externalID ? item.externalID : "",
            phone: item?.phone ? item.phone : "",
            userID: item?.userID ? item.userID : "",
          };
          this.candidateObj.push(customObj);
        }
      });
      document.body.classList.add("no-scroll");
      this.showFlyout("bulk-comms-flyout");
    },
    wOpenCommsFlyout(type) {
    if(this.wSelectAllCandidates) {
      this.commsFlyoutType =type;
      this.displayEmailEditor = true;
      this.candidateObj = [];
      
        const filtered  = this.filteredCandidates;
        filtered.forEach((item)=>{
          let obj = {
            name: item.name,
            partnerID: item.partnerID,
          }
          this.candidateObj.push(obj)
        })
      this.showFlyout('bulk-comms-flyout')
      } else {
       this.errorToast();
      }
      
    },
    sendEmail(event) {
      const payload = {
        html :event.html,
        subject: event.subject,
        action: 'EMAIL',
        userID: this.getUserID,
        filterData: JSON.parse(window.atob(this.candidateCountPayload())),
        type: 'CANDIDATE'
      }
      restApi.post("/onboard/bulkAction",encodeWithParam(payload)).then((data)=>{
        this.successToast(data)
      })
      this.displayEmailEditor = false;
    },
    deleteBulkCandidates() {
      const payload = {
        action: 'DELETE',
        type: 'CANDIDATE'
      }
      restApi.post("/onboard/bulkAction",encodeWithParam(payload)).then((data)=>{
        console.log(data);
        micromodal.close("bulk-delete-candidate-modal");
        this.newOnboardingCandidateView();
      })
    },
    sendSMS(event) {
      const payload = {
        body: event.body,
        sendDate: event.sendDate,
        action:'SMS',
        userID:this.getUserID,
        filterData: JSON.parse(window.atob(this.candidateCountPayload())),
        type: 'CANDIDATE'
      }
      restApi.post("/onboard/bulkAction",encodeWithParam(payload)).then((data)=>{
        this.successToast(data)
      })
      this.displayEmailEditor = false;
    },
    wRunMvr() {
      if(this.wSelectAllCandidates) {
        const payload = {
        action: 'MVR',
        type:'CANDIDATE',
        filterData: JSON.parse(window.atob(this.candidateCountPayload()))
      }
      restApi.post("/onboard/bulkAction",encodeWithParam(payload)).then((data)=>{
        this.successToast(data)
        this.newOnboardingCandidateView();
      })
      } else {
       this.errorToast();
      }    
    },
    successToast(data) {
      this.displayToast=true;
      this.toastSeverity="success";
      this.toastMessage = data.data.message;
      this.wSelectAllPartners = false;
    },
    errorToast() {
      this.toastMessage="Click on select all "
      this.displayToast = true;
      this.toastSeverity="error"
    },
    closeBulkComms() {
      this.candidateObj = [];
      this.wSelectAllCandidates = false;
      this.showFlyout("");
    },
    closeComms() {
      this.perPage = 50;
    },
    refresh() {
      this.perPage = 50;
      this.newOnboardingCandidateView();
    },
    async approvedCount(stageCounts) {
      // let count = stageCounts.filter((item)=>item.weekly === this.getRecruitingStartDate("SATURDAY"));
      // return count.length > 0 ? Number(count[0].count) : 0
      const data = await restApi.get(`/location/getPendingHCR`)
      let count = stageCounts.filter((item)=>item.weekly === data.data.pendingHCR[0]?.startDate);
      return count.length > 0 ? Number(count[0].count) : 0
    },
    
    getRecruitingStartDate (day) {
        var currentDate = new Date();
        var startDate = 1;
        switch (day) {
            case "MONDAY":
                startDate = 6;
                break;
            case "TUESDAY":
                startDate = 5;
                break;
            case "WEDNESDAY":
                startDate = 4;
                break;
            case "THURSDAY":
                startDate = 3;
                break;
            case "FRIDAY":
                startDate = 2;
                break;
            case "SATURDAY":
                startDate = 1;
                break;
            case "SUNDAY":
                startDate = 0;
                break;
        }
        return new Date(currentDate.setDate(currentDate.getDate() - (currentDate.getDay() + startDate) % 7)).toISOString().split("T")[0];
    },
    async totalApprovedCount(totalCandidates) {
      // const startweek = this.startDayWeek > DateTime.now().weekday ? DateTime.now().weekNumber - 1 : DateTime.now().weekNumber;
      // const approvedCandidates = totalCandidates.filter((item) => item.startDate === DateTime.utc().startOf("day").set({ weekNumber: startweek, weekday: this.startDayWeek }).toFormat("yyyy-MM-dd"));
      // return approvedCandidates && approvedCandidates?.length > 0 ? approvedCandidates[0].count : 0;
      const data = await restApi.get(`/location/getPendingHCR`)
      const approvedCandidates = totalCandidates.filter((item) => item.startDate === data.data.pendingHCR[0]?.startDate)
      return approvedCandidates && approvedCandidates?.length > 0 ? approvedCandidates[0].count : 0;
    },
    deleteCandidate(parnerId) {
      this.deleteCandidateId = "";
      this.deleteCandidateId = parnerId;
      micromodal.show("delete-candidate-modal", {});
    },
    confirmationModal() {
      this.deletingLoader=false;
      restApi.post("/onboard/deletePartner",encodeWithParam({partnerID:this.deleteCandidateId})).then(() => {
        this.deletingLoader=true;
        this.newOnboardingCandidateView();
        micromodal.close("delete-candidate-modal");
      });
    },
  selectAllLocations(event){
    if(event.target.checked){
      this.totalCount=0;
      this.selectAllLocationsFlag=true;
      this.newOnboardingCandidateView(); 
    }
    if(!event.target.checked){
      this.totalCount=0;
      this.selectAllLocationsFlag=false;
      this.newOnboardingCandidateView();
    }
  },
  getFilteredLocations(){
    let locations=[];
    if(this.selectAllLocationsFlag){
      locations=this.getAllLocationsData;
    }
    else{
      locations=this.getLocationFilterData;
    }
    return locations;
  }, 
  searchMatch(filter){
    let searchData="";
    let isPhone = /^[0-9]*$/;
    let isEmail = /\S+@\S+\.\S+/;
    if(filter === "email"){
      if(isEmail.test(this.searchTerm)){
        searchData=this.searchTerm;
      }
      else{
        searchData = "";
      }
    }
    if(filter === "phone"){
      if(isPhone.test(this.searchTerm)){
        searchData=this.searchTerm;
      }
      else{
        searchData = "";
      }
    }
    if(filter === "fullName"){
    if(!isEmail.test(this.searchTerm) && !isPhone.test(this.searchTerm)){
      
      searchData=this.searchTerm
    }
    else{
      searchData = "";
    }
    }
     return searchData
  },
  stepsPayload() {
    if(this.selectedStage && this.clickedStep) {
      return [this.clickedStep]
    } else if(this.selectedStage) {
      return this.fp.getStepsByStage(this.selectedStage).map((item)=>item.name)
    } else {
      return this.fp.getAllSteps().map((item)=>item.name)
    }
  },
  statusPayload() {
    if(this.selectedStage && this.clickedStep && this.clickedStatus) {
      return [this.clickedStatus]
    } else if (this.selectedStage && this.clickedStep) {
      return this.fp.getStatusByStep(this.clickedStep).map((item)=>item.name)
    } else {
      return this.fp.getAllStatus().map((item)=>item.name)
    }

  },
  candidatePayload(){
    if(this.searchTerm !== ""){
      this.resetPaginator();
    }
    let data={
        userID: this.getSelectedEmployee.id,
        locationIds:this.getFilteredLocations(),
        stages: this.selectedStage ? [this.selectedStage ] : this.fp.getAllStageNames(),
        steps:  this.stepsPayload() ,
        status:this.statusPayload(),
        phone:this.searchMatch("phone"),
        email:this.searchMatch("email"),
        fullName:this.searchMatch("fullName"),
        limit:this.perPage,
        offset: this.offset,
    }
      let payload=JSON.stringify(data)
      return window.btoa(payload)
    },
    candidateCountPayload(){
      let data={
          userID: this.getSelectedEmployee.id,
          locationIds:this.getFilteredLocations(),
          stages:this.selectedStage ? [this.selectedStage ] : this.fp.getAllStageNames(),
          steps: this.stepsPayload(),
          status:this.statusPayload(),
          phone:this.searchMatch("phone"),
          email:this.searchMatch("email"),
          fullName:this.searchMatch("fullName"),
          limit:0,
          offset: 0
      }
      let payload=JSON.stringify(data)
      return window.btoa(payload)
    },
    cancelOnboardingAggregate() {
      if(this.cancelCandidateViewSource) {
        this.cancelCandidateViewSource.cancel('cancel previous call');
      }
    },
    async newOnboardingCandidateView(){
      this.onboardingCandidateView=[];
      this.onboardingCandidateViewLoading=true;
      this.cancelOnboardingAggregate();
      this.cancelCandidateViewSource = axios.CancelToken.source();
      
      restApi.post(`/employee/onboardingCandidateViewCount`,{"param":this.candidateCountPayload()},
        {cancelToken:this.cancelCandidateViewSource.token}).then((data)=>{
          this.totalCount=Number(data?.data?.result[0]?.total_count);
          if ((this.getLocationFilterData.length > 0 || this.selectAllLocationsFlag) && this.totalCount) {
            restApi.post(`/employee/onboardingCandidateViewV2`,{"param":this.candidatePayload()},
            {cancelToken:this.cancelCandidateViewSource.token}).then((data)=>{
              const ids = data?.data?.result.map(({ partnerID }) => partnerID);
              const filtered = data?.data?.result.filter(({ partnerID }, index) =>
                  !ids.includes(partnerID, index + 1));
            this.cancelCandidateViewSource= null;
            this.onboardingCandidateView = filtered;
            this.onboardingCandidateViewLoading=false;
            });
          } else {
            this.onboardingCandidateView=[];
            this.onboardingCandidateViewLoading=false;
          }
          
      })
    },
    convertDate(date){
      let fullDate=date.split('T');
      return fullDate[0]
    },
    setFunnelSteps(event) {
      if(event.length) {
        this.selectedStage = event[1].name;
        this.selectedSteps = this.fp.getStepsByStage(this.selectedStage);
      } else {
        this.selectedStage ="";
        this.selectedSteps = []
      }
    },
    setFunnelStatus(event) {
      if(event.length) {
        this.selectedStep = event[1].name;
        this.selectedStatus = this.fp.getStatusByStep(this.selectedStep)
      } else {
        this.selectedStep = "";
        this.selectedStatus =[];
      }
    }
  },
};
</script>
<style scoped>
.error{
  border-color: #c2272c;
}
.toast {
  width: 40%;
  margin: -18px auto 0 auto;
}
.toast1{
  width: 40%;
  margin: -18px auto 0 auto;
  height: 20%;

}
.p-message.p-message-error {
  background: #c2272c;
  color: #ffffff;
}
.p-message.p-message-success {
  background: #2A8703;
  color: #ffffff;
}
.inherit-flex {
  flex: inherit !important;
}
.paginator-align {
  margin-bottom: -40px !important;
}
.upload-container {
  height: 264px;
  width: 550px;
  background: #F8F8F8;
  border: 1px solid #EBEBEB;
  align-items: center;
  position:relative
}


.upload-drp-container {
  top: 35%;
  left: 0%;
  right: 0%;
  text-align: center;
  position:absolute;
  margin: -25px 0 0 -25px;

}
.upload-drp-container span{
  font-size: 44px;
    color: #16789e;
}
.upload-progress-container {

  text-align: center;
  position:absolute;
  font-size: 44px;
  color: #16789e;
}
.upload-btn-container {
  top: 55%;
  left: 0%;
  right: 0%;
  text-align: center;
  position:absolute;
  margin: -25px 0 0 -25px;
}

.upload-browse-container {
  top: 70%;
  left: 0%;
  right: 0%;
  text-align: center;
  position:absolute;
  margin: -25px 0 0 -25px;
}

.upload-warning-container{
  top: 95%;
  left: 0%;
  right: 0%;
  text-align: center;
  position:absolute;
  margin: -25px 0 0 -25px;
  color: #8D95AA;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}

.upload-cancel-btn {
  padding-top: 30px;
}
.modal-container.uploadCsv{
  width:auto;
  max-width: none;
}
.modal-container.uploadCsv .upload-container{
  width: auto;
  min-width: 650px;
}

.table-container tbody:nth-child(2) tr:nth-last-child(2) {
  border-bottom: 4px solid var(--primary);
}

.table-container tr:nth-child(even) {
            background-color: #f4f4f4;
        }

.icon-trash-2{
  color: red;
}
.record-count{
  margin-top: 30px;
  margin-left: 15px;
  margin-bottom: 0px;
  font-weight: 600;
}
.driverTable td,.driverTable th{
  color: #333;
}
.driverTable th{
  padding-bottom: 25px;
}
.errorClass{
  border: solid red;
}

.invalidFileClass{
  background-color: #e61641;
    border-radius: 4px;
    color: white;
    padding: 10px 20px;
    margin-top: 15px;
}
.disable{
  background-color: lightblue;
    cursor: not-allowed;
    opacity: 0.6;
}
</style>